/*----Theme Settings----*/
$primaryColor: #861F41;
$secondaryColor: #F0F1F5;
$activeColor: $primaryColor;
$diamondColor: #C11F27;
$primaryOverlayColor: #275790;
$smallFontBase: 16px;
$defaultFontBase: 16px;
$white: #fff !default;
$black: #1D2733 !default;

$white-smoke: #f3f3f3;
$deep-pink: #861F41;
$snow: #fafafa;
$dark-pink: #B23B6C;
$cannon-pink: #895061;
$night-rider: #333333;
$matterhorn: #535353;
$dim-gray: #666666;
$gray: #868686 !default;
$nobel: #979797;
$nobel: #979797;
$siren: #652239;
$lipstick: #A1305C;
$light-sea-green: #9CDBD9;
$half-baked: #4F868E;
$gainsboro: #E1E1E1;
$nero: #212121;
$whisper: #E8E8E8;

$underlineColor: $activeColor;

/*----Foundation Overrides----*/
$grid-margin-gutters: (
    small: 20px,
    medium: 64px
);
$anchor-color: $activeColor;
$anchor-color-hover: $activeColor;

$body-background: $white;
$body-font-color: $black;
$body-font-family: 'canada-type-gibson';

/*----Buttons----*/
$buttonRadius: 3px;
$button-height: 2.5em;
$button-border-radius: $button-height / 2;
$button-padding: 2.5em .75em;

$buttonColor: $primaryColor;
$buttonActiveColor: #B71228;

$buttonColorBlack: #333333;
$buttonActiveColorBlack: #131313;
$preferred_font: $body-font-family;

/*----Links----*/
$linkColor: $activeColor;
$linkHoverColor: $activeColor;

$light-blue: #A5B9D1;
$red: #F71634 !default;
$link-clr: $black !default;
$link-hover: #ff801e !default;
$color-4d: #4d4d4d !default;
$site-color: #ff801e !default;
$gray: #808080 !default;
$gray-lt: #e5e5e5 !default;
$gray-ltr: #bcbcbc !default;

$gray-2: lighten($black, 13.5%) !default; // #222
$gray-3: lighten($black, 20%) !default; // #333
$gray-5: lighten($black, 33.5%) !default; // #555
$gray-7: lighten($black, 46.7%) !default; // #777
$gray-e: lighten($black, 93.5%) !default; // #eee
$gray-f4: lighten($black, 95.5%) !default; // #f4f4f4
$gray-f5: lighten($black, 96.3%) !default; // #f5f5f5
$gray-f7: lighten($black, 96.5%) !default; // #f7f7f7

$body-clr: $white !default;
$header-clr: $white !default;
$footer-clr: $white !default;
$nav-clr: $white !default;

$tab-border-color: #808080 !default;
$table-row-color: #ebecee !default;
$table-row-color-even: #d9dadd !default;

/*RTE*/
$blue: #224460;
$blue-light: #00a7ce;
$blue-dark: #1b4863;
$blue-alternate: #0173A7;
/*---------------------
   2. Box sizing
-------*/
$box-sizing: border-box !default;
$global: 5px !default;
$full-width: 100% !default;
$half-width: 50% !default;
$max-width: 100% !default;
$min-width: 150px !default;
$full-height: 100% !default;
$max-height: 100% !default;
$min-height: 1px !default;

$site-font: 'Roboto' !default;
$rem-base: 16px !default;

// Custom Breakpoints
$breakpoints: (
    small: 0,
    medium:640px,
    large: 1024px,
    xlarge: 1200px
);

$breakpoint-classes: (small medium large xlarge);

$default-padding: 3em;


/* Font */
$font-site: 'canada-type-gibson'!default;
$font-calluna: 'calluna'!default;
$font-futura: 'futura-pt-condensed'!default; 
$line-height: 1 !default;
$regular: 400 !default;
$light: 300 !default;
$semibold: 600 !default;
$bold: 700 !default;
$italic: italic !default;
$letter-spacing: 1pt !default;

/* Container */
$container-width: 1200px;

/* Off-canvas */
$offcanvas-sizes: (
  small: 100%,
  medium: 500px
);
$offcanvas-vertical-sizes: (
  small: 300px,
  medium: 350px
)!default;

/*---- SPACING ----*/
$margins: (1: 1rem, 2: 3rem, 3: 6rem, 4: 10rem);
$paddings: (1: 1rem, 2: 3rem, 3: 6rem, 4: 10rem);